<template>
  <div id="app">
      <!--
    <img src="./assets/logo.png">
    <div>
      <p>
        If Element is successfully added to this project, you'll see an
        <code v-text="'<el-button>'"></code>
        below
      </p>
      <el-button>el-button</el-button>
    </div>
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    -->
    <router-view></router-view>
    <div v-if="showHome" id="home"></div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

// import { UrlHelper } from './utils/Utils'
// import { store } from './utils/store'
// import { MessageProxy } from './utils/MessageProxy'

export default {
    name: 'app',
    components: {
    // HelloWorld
    },
    created() {
      this.$router.beforeEach((to, from, next) => {
        // console.log('路由发生了改变')
        // 这里可以编写处理路由变化的逻辑
        if (location.hash == '#/' || location.hash == '' || location.hash == '#' ) {
              this.showHome = true
        }else{
            this.showHome = false
        }
        next(); // 必须调用next()才能完成路由切换
      })
    },
    beforeCreate() {
      
      // let wxOpenId = UrlHelper.parse('openid');
      // if (undefined !== wxOpenId && "" !== wxOpenId) {
      //   store.wxOpenId = wxOpenId;
      // }
      //     console.log(location)
      //     store.originalUrl = location.href;
      //     store.hostUrl = location.origin + "/";
      //     let pos = location.href.lastIndexOf("/");
      //     store.baseUrl = location.href.substring(0, pos) + "/";
      //     store.imageUrl = store.baseUrl + "images/";
      //     pos = location.href.indexOf("?");
      //     store.actualUrl = location.href.substring(0, pos);

      //     let hospitalShortName = undefined;
      //     if (location.hostname.endsWith('efilm.top') == true) {
      //         pos = location.hostname.indexOf(".");
      //         hospitalShortName = location.hostname.substring(0, pos);
      //         // console.log(store.hospitalShortName)
      //     }

      //     let studyId = UrlHelper.parse('studyid');
      //     if (undefined !== studyId && "" !== studyId) {
      //         // console.log('hospitalShortName ' + hospitalShortName)
      //         if (undefined == hospitalShortName || '' == hospitalShortName) {
      //             hospitalShortName = UrlHelper.parse('hospital');
      //             if (undefined == hospitalShortName || '' == hospitalShortName) {
      //                 // this.$message.error('无法识别医院。');
      //                 this.$message({
      //                     message: '无法识别医院。',
      //                     type: 'error',
      //                     duration: 0
      //                 });
      //                 return;
      //             }
      //         }
      //         store.hospitalShortName = hospitalShortName;
      //         // console.log(store.hospitalShortName)
      //         this.$router.push('/studyinfo/' + studyId);

      //         return;
      //     }

    //     let token = UrlHelper.parse('token');
    //     if (undefined !== token && "" !== token) {
            
    //         return;
    //     }


    //     let wxOpenId = UrlHelper.parse('openid');
    //     if (undefined !== wxOpenId && "" !== wxOpenId) {
    //         store.wxOpenId = wxOpenId;

    //         MessageProxy.getUserInfo(wxOpenId).then((result) => {
    //             if (true == result.hasError) {
    //                 this.$message.error(result.errorText);
    //                 return;
    //             }
    //             if (result.data != null && result.data.code != '200') {
    //                 this.$message.error(result.data.message);
    //                 return;
    //             }
    //             if (result.data.data != undefined && result.data.data.length > 0 &&
    //                 result.data.data[0].userId != undefined && result.data.data[0].userId != "") {
    //                 store.userId = result.data.data[0].userId;
    //             }

    //             this.$router.push('/main');
    //             this.$router.replace('/main/concern');

    //             let operation = `由微信公众号登入 OpenID=${store.wxOpenId}`;
    //             if (store.userId != undefined && store.userId != "") {
    //                 MessageProxy.addUserLog(store.userId, operation).then(() => {
    //                 })
    //             } else {
    //                 MessageProxy.addUserLog(store.wxOpenId, operation).then(() => {
    //                 })
    //             }
    //         })
    //         return;
    //     }
        
    //     this.$router.push('/login');
    },
    data () {
      return {
        showHome: false
      }
    }
}
</script>
<style>
.el-message{
  min-width: unset!important;;
}
</style>
<style scoped>
#home{
    height: 100%;
    background: url(./assets/bg-home.png);
    background-size: cover;
    width: 100%;
    position: absolute;
    z-index: 111;
}
</style>
<style>
html {
    height: 100%;
}
body {
    height: 100%;
    margin: 0;
    background-color: #f9f9f9;
    overflow: hidden;     /*阻止手机浏览器默认的下拉刷新*/
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  height: 100%;
}
body .el-message-box {
    width: 90vmin;
}
</style>
